<template>
	<dashboard-container
		title="Admins"
	>
		<section class="h-100">			
			<!-- User table list -->
			<div v-if="!error" class="table-responsive">
				<table class="table table-hover table-md">
					<caption>List of admins</caption>
					<thead>
						<tr>
							<th class="border-top-0" scope="col">Email</th>
							<th class="border-top-0" scope="col">Clinic</th>
							<th class="border-top-0 table-action" scope="col">Actions</th>
						</tr>
					</thead>
					<!-- Placeholder data -->
					<tbody v-if="loading" class="table-loading">
						<tr v-for="tr in 5" :key="tr">
							<td v-for="td in 3" :key="td">
								<span></span>
								<span class="sr-only">Loading...</span>
							</td>
						</tr>
					</tbody>
					<!-- User Entries -->
					<tbody v-else>
						<tr
							v-for="(user, i) in users"
							:key="`user-${i}`"
						>
							<th scope="row">
								{{ user.email }}
							</th>
							<td>
								{{ user.clinic }}
							</td>
							<td class="table-action">
								<div class="btn-group" role="group" aria-label="User Actions">
									<router-link
										class="btn btn-primary"
										title="View user"
										:to="{
											name: 'UpdateAdmin',
											params: { id: user.username }
										}"
									>
										<eye-icon size="1x" />
										<span class="sr-only">View</span>
									</router-link>
									<button
										type="button"
										class="btn btn-danger"
										title="Delete user"
										@click="showModal(user.username)"
									>
										<x-icon size="1x" />
										<span class="sr-only">Delete</span>
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<Alert v-else error type="danger">
				<small>{{ error }}</small>
			</Alert>

		</section>
	</dashboard-container>
</template>

<script>
// Components
import dashboardContainer from '@/components/admin/dashboard-container'
import Alert from '@/components/general/alert'

// Icons
import { XIcon, EyeIcon } from 'vue-feather-icons'

export default {
	components: {
		dashboardContainer,
		Alert,
		EyeIcon,
		XIcon
	},
	data() {
		return {
			loading: true,
			error: '',
			users: null
		}
	},
	methods: {
		async showModal(id) {
			await this.$store.dispatch('setEntry', id)
			await this.$store.dispatch('showModal', true)
		},
		async getUsers() {
			this.loading = true

			try {
				const response = await this.$api.get(
					'/v1/user/list', {
						params: {
							limit: 60
						}
					}
				)
				const data = await response.data.data.users

				// Set users list and remove loading
				this.users = data
				this.loading = false
				
			} catch(error) {
				this.loading = false

				if (error.result) {
					// The request was made and the server responded
					this.error = error.result.data['meta']['error']['error_message']
				} else if (error.request) {
					//The request was made but no response was received,
					let errorMessage = JSON.parse(error.request.response)
					errorMessage = errorMessage['meta']['error']['error_message']
					this.error = errorMessage
				} else {
					// Something happened in setting up the request and triggered an Error
					this.error = error.message
				}
			}
		},
		resetState() {
			// Remove error and stored entry
			this.$store.dispatch('setEntry', '')
			this.$store.dispatch('setAdminError', '')
		}
	},
	created() {
		this.getUsers()
		this.resetState()
	}
}
</script>